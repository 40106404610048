import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { ValuationAdvisorySubRoute } from "../../constant/Menu";
import { ServiceWiseData } from "../../constant/ServicesData";
import ServiceData from "./components/ServiceData";

const ServicesPages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    if (
      !ValuationAdvisorySubRoute.find(
        (f) => f.link === location?.pathname.trim()
      )
    )
      window.location.replace("/");

    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div className="header-gutter" />
      <ServiceData {...ServiceWiseData[location?.pathname.trim()]} />
    </>
  );
};

export default ServicesPages;
