import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./assets/css/style.css";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import ServicesPages from "./pages/Services Page";
import AboutUsPage from "./pages/About Us";
import ContactPage from "./pages/ContactUs";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/:id" element={<ServicesPages />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default App;
