import React from "react";

const Services = React.lazy(() => import("../Services"));
const Main = React.lazy(() => import("../Main"));
const Skill = React.lazy(() => import("../Skill"));
const Counter = React.lazy(() => import("../Counter"));
const BlogNews = React.lazy(() => import("../BlogNews"));
const Teams = React.lazy(() => import("../Teams"));
const Feedback = React.lazy(() => import("../Feedback"));
const Delivery = React.lazy(() => import("../Delivery"));
// const LatestBlogNews = React.lazy(() => import("../Latest Blog & News"));
const ClientBrands = React.lazy(() => import("../Client Brand"));
const Home = () => {
  return (
    <>
      <Main />
      <Services />
      <Skill />
      <Counter />
      <BlogNews />
      <Teams />
      <Feedback />
      <ClientBrands />
      <Delivery />
      {/* <LatestBlogNews /> */}
    </>
  );
};

export default Home;
