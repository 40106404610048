import $ from "jquery";
import React, { useEffect } from "react";
import "slick-carousel";

const Slider = ({ data }) => {
  useEffect(() => {
    $(".testimonial-slider").slick({
      dots: false,
      arrows: false,
      autoplay: true,
      slidesToShow: 3,
      infinite: true,
      slidesToScroll: 1,
      autoplaySpeed: 800,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }, []);
  return (
    <div className="container container_testimonial mt-120">
      <div className="row">
        <div className="col-12">
          <div className="testimonial-slider">
            {data?.map((item, index) => (
              <div key={index} className="slider-item">
                <div className={`testimonial__item ${item?.border}`}>
                  <div className="testimonial__item-header d-flex justify-content-between align-items-center mb-35 mb-sm-25 mb-xs-20">
                    <div className="left d-flex align-items-center">
                      <div className="media overflow-hidden">
                        <img src={item?.img} className="img-fluid" alt="" />
                      </div>
                      <div className="meta">
                        <h6 className="name fw-500 text-uppercase color-d_black">
                          {item?.title}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="description font-la mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                    <p>{item?.Description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
