import React from "react";
import { Link } from "react-router-dom";
import pageBannerStart from "../../assets/images/page-banner-start.svg";
import pageBanner from "../../assets/images/page-banner.jpg";
import badgeLine from "../../assets/images/badge-line.svg";
import Company1 from "../../assets/images/our-company-1.png";
import Company2 from "../../assets/images/our-company-2.png";

const AboutUs = () => {
  return (
    <>
      <div className="header-gutter" />
      <section className="page-banner pt-xs-60 pt-sm-80 overflow-hidden">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="page-banner__content mb-xs-10 mb-sm-15 mb-md-15 mb-20">
                <div className="transparent-text">About Us</div>
                <div className="page-title">
                  <h1>
                    Know Our About <span>Company</span>
                  </h1>
                </div>
              </div>

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">About Us</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6">
              <div className="page-banner__media mt-xs-30 mt-sm-40">
                <img src={pageBannerStart} className="img-fluid start" alt="" />
                <img src={pageBanner} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-company  pt-xs-80 pb-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="our-company__meida">
                <img src={Company1} alt="" className="img-fluid" />

                <div className="years-experience overflow-hidden mt-20 mt-sm-10 mt-xs-10 text-center">
                  <div className="number mb-5 color-white">
                    <span className="counter">23</span>
                    <sup>+</sup>
                  </div>

                  <h5 className="title color-white">Years Experience</h5>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="our-company__meida border-radius">
                <img src={Company2} alt="" className="img-fluid" />

                <div className="horizental-bar"></div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="our-company__content mt-md-50 mt-sm-40 mt-xs-35">
                <span className="sub-title fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-20 d-block">
                  <img src={badgeLine} className="img-fluid mr-10" alt="" />
                  Know Our Company
                </span>
                <h2 className="title color-d_black mb-20 mb-sm-15 mb-xs-10">
                  Delivering Trustworthy Valuations: Assured Consultancy
                  Services
                </h2>

                <div className="descriiption font-la mb-30 mb-md-25 mb-sm-20 mb-xs-15">
                  <p>
                    Assured Consultancy Service is a leading provider of
                    Valuation of Real Estate Assets & Plant and Machinery,
                    Project Approval and Project Monitoring services across top
                    cities in India. Leading institutions in BFSI and Insurance
                    segment trust PropEdge for independent and incisive
                    Technical services reports.
                  </p>
                  <p>
                    Our team of highly skilled experienced engineers provides
                    unrivalled service by leveraging in-depth Market
                    understanding combined with comprehensive data and cutting
                    edge technology.
                  </p>
                  <p>
                    Our scalable technical services platform assists our clients
                    in multiple facets of their decision making related to their
                    investment and lending decision. Over past few years we have
                    emerged as a one stop technical services shop for our
                    clients.
                  </p>
                  <p>
                    Focusing on the quality and timeliness of services rendered,
                    our committed team believes in transforming the prevalent
                    service levels and setting up new benchmarks in the
                    Industry.
                  </p>
                </div>

                <div className="client-feedback d-flex flex-column flex-sm-row">
                  <div className="client-feedback__item text-center">
                    <div className="client-feedback__item-header">
                      <span className="color-primary font-la fw-600 text-uppercase">
                        Years of Experience
                      </span>
                    </div>

                    <div className="client-feedback__item-body">
                      <div className="number mb-10 mb-xs-5 color-d_black fw-600">
                        +<span className="counter">4.7</span>
                      </div>
                      <div className="description font-la mb-10 mb-xs-5">
                        <p>
                          A Legacy of Trust: Decades of experience delivering
                          accurate and reliable valuations for our clients
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="client-feedback__item text-center">
                    <div className="client-feedback__item-header">
                      <span className="color-primary font-la fw-600 text-uppercase">
                        Team Expertise
                      </span>
                    </div>

                    <div className="client-feedback__item-body">
                      <div className="number mb-10 mb-xs-5 color-d_black fw-600">
                        +<span className="counter">7</span>
                      </div>
                      <div className="description font-la mb-10 mb-xs-5">
                        <p>
                          Leveraging a team of highly skilled engineers and
                          valuation specialists.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="client-feedback__item text-center">
                    <div className="client-feedback__item-header">
                      <span className="color-primary font-la fw-600 text-uppercase">
                        Client Testimonials
                      </span>
                    </div>

                    <div className="client-feedback__item-body">
                      <div className="number mb-10 mb-xs-5 color-d_black fw-600">
                        +<span className="counter">10</span>
                      </div>
                      <div className="description font-la mb-10 mb-xs-5">
                        <p>
                          We consistently exceed expectations for clients across
                          diverse industries.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="client-feedback__item text-center">
                    <div className="client-feedback__item-header">
                      <span className="color-primary font-la fw-600 text-uppercase">
                        Industry Awards/Recognition
                      </span>
                    </div>

                    <div className="client-feedback__item-body">
                      <div className="number mb-10 mb-xs-5 color-d_black fw-600">
                        +<span className="counter">7.3</span>
                      </div>
                      <div className="description font-la mb-10 mb-xs-5">
                        <p>
                          Consistently ranked among the top valuation firms in
                          surat/Assured Consultancy Services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
