import enterpriseValuationAndBusinessValuation from "../assets/images/enterprise-valuation-business-valuation.jpg";
import valuationOfIndustrialAssets from "../assets/images/valuation-of-industrial-assets-plant-machinery.jpg";
import valuationOfInfrastructureAssets from "../assets/images/valuation-of-infrastructure-assets-specialized-assets.jpg";
import valuationOfIntangibleAssets from "../assets/images/valuation-of-intangible.jpg";
import valuationOfRealEstate from "../assets/images/valuation-of-real-estate.jpg";
import valuationOfIntellectualProperties from "../assets/images/valuation-of-intellectual-properties.jpg";
import valuationOfEsopsSweatEquity from "../assets/images/valuation-of-esops-sweat-equity.jpg";
import valuationForMergerAcquisition from "../assets/images/valuation-for-merger-and-acquisition.jpg";
import fairnessOpinions from "../assets/images/fairness-opinions.jpg";
import valuationForInsurance from "../assets/images/valuations-for-insurance.jpg";
import { sliderForRealEstate } from "./SliderData";

export const ServiceWiseData = {
  "/valuation-of-real-estate": {
    boldHeader: "Valuation of Real ",
    unBoldHeader: "Estate",
    title: "Valuation of Real Estate",
    bannerImg: valuationOfRealEstate,
    boxNumber: 18,
    boxTitle: "Years Of Excellence",
    description1:
      "Property / Real Estate investment is one of the major investment process which needs a big amount of your savings and to make it a successful real estate purchase a complete valuation of the property becomes mandatory. Whether is a land, building or a commercial properties a complete measure makes the process more safe and secure.",
    description2:
      "A proper planning and complete guideline from experts helps to easily cross the pits and falls in real estate industry. With a complete and in-depth knowledge about the real estate industry, we provide wide range of services like, advisory, investor guideline, legal obligation, complete analysis on the competitive market value, property acquisition etc.",
    isSlider: true,
    sliderData: sliderForRealEstate,
  },
  "/valuation-of-industrial-assets": {
    boldHeader: "Valuation Advisory ",
    unBoldHeader: "Services",
    title: "Valuation Of Industrial Assets, Plant & Machinery",
    bannerImg: valuationOfIndustrialAssets,
    boxNumber: "15+",
    boxTitle: "Regional Offices",
    description1:
      "With a day-to-day upsurge in population, highly developed infrastructure is needed to meet the basis demand of every citizen. Since the country economic growth is fully depended on the infrastructure, the government is investing an enormous amount of money towards it's development and which in turn provides an abundant opportunity for the public and private sector.",
    description2:
      "The infrastructure development is witnessed across every sector like road, railways, airports, communication, power and urban infrastructure. So, it is necessity for a business to understand what's their potential is, which can be derived only through valuation. Since most of the income is generated by infrastructure and specialized assets like plant, machinery etc., valuations of these assets help the business to understand their current potential and on how to move forward with the ample opportunities available in the industry.",
    isSlider: true,
    sliderData: sliderForRealEstate,
  },
  "/enterprise-valuation-and-business-valuation": {
    boldHeader: "Valuation Advisory ",
    unBoldHeader: "Services",
    title: "Enterprise Valuation and Business Valuation",
    bannerImg: enterpriseValuationAndBusinessValuation,
    boxNumber: "10,00,000",
    boxTitle: "Appraisals and Valuations",
    description1:
      "Business valuation is impacted by a multitude of factors such as the subject company’s industry, its stage of development and the source of the invested capital. Additionally, the variety of purposes for which business valuations are performed, the influence of local jurisdictional rules and available valuation methods further increase the complexity of business valuation.",
  },
  "/valuation-of-intangible-assets": {
    boldHeader: "Valuation Advisory ",
    unBoldHeader: "Services",
    title: "Valuation of Intangible Assets",
    bannerImg: valuationOfIntangibleAssets,
    boxNumber: "350+",
    boxTitle: "Professionals.",
    description1:
      "The valuation of intangible assets is the process of determining the value of a business's non-physical assets. These assets include: Intellectual property, such as patents, copyrights, and trademarks Brand recognition ",
    description2:
      "Intellectual property, such as patents, copyrights, and trademarks Brand recognition Customer relationships Proprietary technology and software The Indian Valuation Standard 103 recommends three valuation approaches for intangible assets:",
    multiDescription: [
      {
        title: "Cost approach",
        description:
          "Determines the cost of replacing or recreating the asset, taking into account the expenses of development, acquisition, and maintenance. This approach is often used to value software development.",
      },
      {
        title: "Market approach",
        description:
          "Determines the value of an asset based on recent transaction data for similar assets in a free and unrestricted market. This approach works well for physical assets, but may not be as easy to apply to intangible assets. ",
      },
      {
        title: "Discounted cash flow model",
        description:
          "Compares the enterprise's discounted cash flow model with and without the asset to determine its value.",
      },
    ],
  },
  "/valuation-of-infrastructure-assets-specialized-assets": {
    boldHeader: "Valuation of Infrastructure Assets ",
    unBoldHeader: "& Specialized Assets",
    title: "Valuation of Infrastructure Assets & Specialized Assets",
    bannerImg: valuationOfInfrastructureAssets,
    boxNumber: "15+",
    boxTitle: "Regional Offices & 16 Service Units",
    description1:
      "With a day-to-day upsurge in population, highly developed infrastructure is needed to meet the basis demand of every citizen. Since the country economic growth is fully depended on the infrastructure, the government is investing an enormous amount of money towards it's development and which in turn provides an abundant opportunity for the public and private sector.",
    description2:
      "The infrastructure development is witnessed across every sector like road, railways, airports, communication, power and urban infrastructure. So, it is necessity for a business to understand what's their potential is, which can be derived only through valuation. Since most of the income is generated by infrastructure and specialized assets like plant, machinery etc., valuations of these assets help the business to understand their current potential and on how to move forward with the ample opportunities available in the industry.",
  },
  "/valuation-of-intellectual-properties": {
    boldHeader: "Valuation of Intellectual ",
    unBoldHeader: "properties",
    title: "Valuation of Intellectual properties",
    bannerImg: valuationOfIntellectualProperties,
    boxNumber: "10,00,000",
    boxTitle: "Appraisals and Valuations",
    description1:
      "The concept of valuation of intellectual property and other intangible assets of a company is new as compared to other concepts of intellectual property (IP) law. The value of an IP is a monetary compensation that is expected to be received from licensing of an IP or from sale or exchange of other intangible assets. The intangible assets of a company include goodwill, trademark, brand, copyright, logos, technology, know how, trade secrets etc.",
  },
  "/valuation-of-esops-sweat-equity": {
    boldHeader: "Valuation Advisory ",
    unBoldHeader: "Services",
    title: "Valuation of ESOPs & Sweat Equity",
    bannerImg: valuationOfEsopsSweatEquity,
    boxNumber: "350+",
    boxTitle: "Professionals.",
    description1:
      "Startup companies, in their infant stages, face uncertainty given the macro cum micro operating environment, availability of continuous stream of funds, ability to make a mark with technological innovations and retain manpower. Employee retention and sustaining their motivation levels has been one of the key concerns for the employers of startup firms.",
    description2:
      "In this regard they always come up with one or the other new innovative and attractive compensation packages structured to target highly skilled niche workforce. Employee stock options (ESOP) and sweat equity are one of the favorite methods of attracting and motivating talent given the companies are illiquid to pay high salaries and need to utilize the freshly infused funds for growing their business.",
  },
  "/valuation-for-merger-and-acquisition": {
    boldHeader: "Valuation for ",
    unBoldHeader: "Merger & Acquisition",
    title: "Valuation for Merger & Acquisition",
    bannerImg: valuationForMergerAcquisition,
    boxNumber: "10,00,000",
    boxTitle: "Appraisals and Valuations",
    description1:
      "An acquisition involves one firm buying only a portion of another firm. The acquisition may happen to acquire assets or an altogether different segment of the other firm. A merger involves the total absorption of a target firm by the acquirer. As a result, one firm ceases to exist and only the new firm (acquirer) remains.",
    description2:
      "In a merger or acquisition transaction, valuation is essentially the price that one party will pay for the other, or the value that one side will give up making the transaction work. Valuations can be made via appraisals or the price of the firm’s stock if it is a public company, but at the end of the day valuation is often a negotiated number.",
  },
  "/fairness-opinions": {
    boldHeader: "Valuation Advisory ",
    unBoldHeader: "Services",
    title: "Fairness Opinions",
    bannerImg: fairnessOpinions,
    boxNumber: "15+",
    boxTitle: "Regional Offices & 16 Service Units",
    description1:
      "High-risk transactions are likely to be heavily scrutinized by various stakeholders with diverse interests and rights. A fairness opinion can help the board (or a special committee of the board) determine whether a proposed transaction is fair to relevant stakeholders.",
    description2:
      "A fairness opinion is a report that evaluates the facts of a merger, acquisition, carve out, spin-off, buyback or another type of purchase and provides an opinion as to whether the proposed stock price is fair to the selling or target company. Fairness opinions are provided to the key decision makers in the target/selling company and are written by qualified analysts or advisors, usually of an investment bank. The analysts examine the specifics of the deal, including any possible business synergies that benefit the target/seller if applicable, the terms of the agreement, and the price offered for the stock of the target/seller. Fairness opinions are not always required in transactions involving public companies but can be helpful in reducing the risk associated with a merger, acquisition, carve out, spin-off, buyback, or another type of purchase, including the risk of litigation. Private transactions may require a fairness opinion.",
  },
  "/valuation-for-insurance": {
    boldHeader: "Valuation for ",
    unBoldHeader: "Insurance",
    title: "Valuation for Insurance",
    bannerImg: valuationForInsurance,
    boxNumber: "15+",
    boxTitle: "Regional Offices & 16 Service Units",
    description1:
      "An insurance valuation is a thorough assessment of the rebuild cost of the property. Insurance Valuations will involve a Chartered Surveyor visiting the property and measuring the property in full to then calculate the cost of rebuild.",
    description2:
      "Asset valuations are project managed by our expert insurance valuers from start to finish to give you the peace of mind that all aspects are taken care of and your insurer is in possession of up to date, robust and accurate valuation reports that will stand up to scrutiny in the event of a claim.",
  },
};

export const valueOfRealEstate = [
  {
    title: "Investment Valuation",
    description: "",
  },
];
