import shipra from "../assets/images/shipra.jpg";
import bannet from "../assets/images/bennett-coleman.jpg";
import reliance from "../assets/images/reliance.png";
import dlf from "../assets/images/DLF.png";
import lalit from "../assets/images/lalit.png";
import marriot from "../assets/images/marriott.png";

export const sliderForRealEstate = [
  {
    border: "border-orange",
    img: shipra,
    title: "Shipra Group Of Hotels",
    Description: "",
  },
  {
    border: "border-primary",
    img: bannet,
    title: "Bennett, Coleman & Co. Ltd.",
    Description: "",
  },
  {
    border: "border-red",
    img: reliance,
    title: "Reliance Center",
    Description: "",
  },
  {
    border: "border-orange",
    img: dlf,
    title: "DLF India",
    Description: "",
  },
  {
    border: "border-primary",
    img: lalit,
    title: " LaLiT Hotels",
    Description: "",
  },
  {
    border: "border-red",
    img: marriot,
    title: "Marriott Hotels",
    Description: "",
  },
];
